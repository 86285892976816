// src/components/WeaponBar.js

import React from 'react';
import './WeaponBar.css';
import ModelViewer from './ModelViewer';

const WeaponBar = () => {
  return (
    <div className="weapon-bar">
      <div className="weapon-box">
        <ModelViewer />
      </div>
      <div className="weapon-box">
        {/* Placeholder for another weapon or item */}
      </div>
      <div className="weapon-box">
        {/* Placeholder for another weapon or item */}
      </div>
    </div>
  );
};

export default WeaponBar;
