import React, { useRef, useState } from 'react';
import './WeaponBar.css';
import gramophoneImg from '../models/gramophone-removebg-preview.png';
import lanternImg from '../models/lantern-removebg-preview.png';
import gunImg from '../models/gun-removebg-preview.png';
import backgroundAudio from '../sounds/background.mp3';

const WeaponBar = () => {
  const [audioStarted, setAudioStarted] = useState(false);
  const audioRef = useRef(null);

  const handleToggleAudio = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    } else {
      audio.pause();
    }
    setAudioStarted(!audioStarted);
  };

  return (
    <div className="weapon-bar">
      <audio ref={audioRef} src={backgroundAudio} loop onError={() => console.error('Audio file could not be loaded or played.')} />
      <div className="weapon-box" onClick={handleToggleAudio}>
        <img src={gramophoneImg} alt="Gramophone" />
      </div>
      <div className="weapon-box">
        <img src={lanternImg} alt="Lantern" />
      </div>
      <div className="weapon-box">
        <img src={gunImg} alt="Gun" />
      </div>
    </div>
  );
};

export default WeaponBar;
