import React from 'react';
import './App.css';
import discordImg from './images/discord.png';
import patreonImg from './images/patreon.png';
import WeaponBar from './components/WeaponBar';

function App() {
  return (
    <div className="App">
      <div className="buttons">
        <a href="https://discord.com" target="_blank" rel="noopener noreferrer">
          <img src={discordImg} alt="Discord" />
        </a>
        <a href="https://patreon.com" target="_blank" rel="noopener noreferrer">
          <img src={patreonImg} alt="Patreon" />
        </a>
      </div>
      <WeaponBar />
    </div>
  );
}

export default App;
